<template>
<div>
    <h1 class="mb-0">Reset Password</h1>
    <!-- <p>Enter your email address and we'll send you an email with instructions to reset your password.</p> -->
    <form class="mt-4" @submit.prevent="onSubmit">

        <div class="form-group">
            <label for="exampleInputEmail1">New Password</label>
            <input type="password" v-model="password" class="form-control mb-0" id="exampleInputPassword1" aria-describedby="passwordHelp" placeholder="Enter New Password">
        </div>

        <div class="form-group">
            <label for="exampleInputEmail1">Confirm Password</label>
            <input type="password" v-model="password_confirmation" class="form-control mb-0" id="exampleInputPassword2" aria-describedby="passwordHelp" placeholder="Enter Confirm Password">
        </div>
        <div>
          <i class="fa-solid fa-circle-info mr-2"></i><span class="iq-alert-text small">Passwords need to contain a mixture of <b>lowercase, uppercase, numbers, special characters</b> and should have <b>8 to 20</b> characters.</span>
        </div>

        <div class="d-inline-block w-100">
            <button type="submit" class="btn btn-primary float-right">Reset Password</button>
        </div>

      </form>


</div>
</template>

<script>
// import axios from 'axios'
export default {
    name: 'reset-password',
    data: () => ({
        password: '',
        password_confirmation: '',
    }),

    methods: {
        onSubmit() {
            this.axios
                .post('/set-password', {
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.$route.params.token
                })
                .then(function (response) {
                    window.location.href = '/auth/login'
                    history.back();     // equivalent to clicking back button
                    history.go(-1);     // equivalent to history.back();
                })
                .catch(function (error) {})
        },
    }
}
</script>
